<template>
    <div class="col-sm-12 col-lg-6 col-md-12">
        <div class="form-group row tw-mb-3">
            <label
                class="col-md-5 form-col-label control-label required tw-text-right tw-p-2 tw-font-bold"
                >{{ $t("shiftManagement.shiftName") }}
            </label>
            <div class="col-md-7">
                <input
                    v-model.trim="model.shift_name"
                    type="text"
                    class="form-control"
                    :class="{
                        'is-invalid': errors.has('shift_name')
                    }"
                    ref="shift_name"
                />
                <div class="invalid-feedback" v-if="errors.has('shift_name')">
                    {{ errors.first("shift_name") }}
                </div>
            </div>
        </div>
        <div class="form-group row tw-mb-3">
            <label
                class="col-md-5 form-col-label control-label required tw-text-right tw-p-2 tw-font-bold"
                >{{ $t("shiftManagement.shiftType") }}
            </label>
            <div class="col-md-7">
                <select
                    v-model="model.shift_type"
                    class="form-select"
                    :class="{ 'is-invalid': errors.has('shift_type') }"
                >
                    <option
                        :value="null"
                        disabled
                        selected
                        style="display: none;"
                        >{{ $t("select") }}</option
                    >
                    <option value="Normal">Normal</option>
                    <option value="Shift">Shift</option>
                    <option value="Rotation">Rotation</option>
                </select>
                <div class="invalid-feedback" v-if="errors.has('shift_type')">
                    {{ errors.first("shift_type") }}
                </div>
            </div>
        </div>
        <div class="form-group row tw-mb-3">
            <label
                class="col-md-5 form-col-label control-label required tw-text-right tw-p-2 tw-font-bold"
                >{{ $t("shiftManagement.creasePeriod") }}
            </label>
            <div class="col-md-7">
                <input
                    v-model.number.trim="model.minute_allow"
                    type="number"
                    class="form-control"
                    :class="{
                        'is-invalid': errors.has('minute_allow')
                    }"
                />
                <div class="invalid-feedback" v-if="errors.has('minute_allow')">
                    {{ errors.first("minute_allow") }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { isEmpty } from "lodash";
import { mapState } from "vuex";

export default {
    name: "shift",
    props: ["value", "validate"],
    data() {
        return {
            errors: new Errors(),
            model: {}
        };
    },
    computed: {
        ...mapState("humanResource/shiftManagement", ["edit_data"]),
        isEdit() {
            return !isEmpty(this.edit_data);
        }
    },
    mounted() {
        this.$refs.shift_name.focus();
    },
    created() {
        this.model = this.value;
    },
    methods: {},
    watch: {
        validate: function(error) {
            this.errors = new Errors(error.errors);
        }
    }
};
</script>
